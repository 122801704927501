//import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from "@angular/core";
/*import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from './../services/user.service';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';*/

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
  styleUrls: ["./maintenance.component.scss"],
})
export class MaintenanceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
