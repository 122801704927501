import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UserService } from "../../services/user.service";
import { map } from "rxjs/operators";
import { STRINGS } from "src/app/utils/constants";
//import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: "app-reset",
  templateUrl: "./reset.component.html",
  styleUrls: ["./reset.component.scss"],
})
export class ResetComponent implements OnInit {
  public forgotForm: UntypedFormGroup;
  public message: string;
  public loading = false;
  public error = false;
  public token = null;
  public validToken = false;
  public invalidTokenMsg = false;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    fb: UntypedFormBuilder
  ) {
    this.forgotForm = fb.group({
      password: ["", Validators.required],
      confirm_password: ["", Validators.required],
    });
    route.params.pipe(map((p) => p.id)).subscribe((id) => {
      this.token = id;
    });
  }

  ngOnInit(): void {
    this.userService.verifyPasswordCode(this.token).subscribe(
      () => {
        this.validToken = true;
      },
      (response) => {
        this.validToken = false;
        this.invalidTokenMsg =
          (response.error && response.error.error_message) || "";
      }
    );
  }

  onSubmit() {
    this.loading = true;
    this.message = "";
    const password = this.forgotForm.value.password;
    const confirm_password = this.forgotForm.value.confirm_password;
    this.userService
      .resetPassword(this.token, password, confirm_password)
      .subscribe(
        () => {
          this.error = false;
          this.message = STRINGS.NEW_PASSWORD_SAVED;
          this.loading = false;
        },
        (response) => {
          this.error = true;
          this.message =
            (response.error && response.error.error_message) ||
            STRINGS.ERROR_OCCURRED;
          this.loading = false;
        }
      );
  }
}
