<script type="text/javascript">
  !(function (o, c) {
    var n = c.documentElement,
      t = " w-mod-";
    (n.className += t + "js"),
      ("ontouchstart" in o ||
        (o.DocumentTouch && c instanceof DocumentTouch)) &&
        (n.className += t + "touch");
  })(window, document);
</script>

<div class="section">
  <div>
    <div class="div-block">
      <img
        src="/assets/img/Grata-Logo-Badge-copy.png"
        loading="lazy"
        alt=""
        class="image"
      />
      <div class="div-block-2">
        <div class="text-block-2">Planned Maintenance in Progress<br /></div>
      </div>
      <div class="div-block-4"></div>
      <div class="div-block-3">
        <div class="div-block-5">
          <div class="text-block-3">
            <strong class="bold-text"
              >Exciting new changes are coming to Grata!</strong
            >
          </div>
          <img
            src="/assets/img/607533c24a784848849feb58_Delighted.png"
            loading="lazy"
            alt=""
            class="image for-smiley"
          />
        </div>
      </div>
      <div class="div-block-4"></div>
      <div class="div-block-3">
        <div class="text-block-4">
          We can&#x27;t wait for you to see them.<br />Please check back
          shortly!<br /><span
            ><strong class="bold-text-2"
              >&quot;Grata&quot; for your patience!</strong
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</div>
<script
  src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60c8d1aa466bd27d007b78da"
  type="text/javascript"
  integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
  crossorigin="anonymous"
></script>
<script src="/assets/img/webflow.js" type="text/javascript"></script>
<!-- [if lte IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js"></script><![endif] -->
