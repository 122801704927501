import { Business } from "./business.model";
import { Experience } from "./experience.model";
import { TeamModel } from "./team.model";
import { environment } from "../../environments/environment";
export class User {
  id: number;
  show_full_name: boolean;
  email: string;
  job_title: string;
  biography: string;
  cover_image_ref: string;
  ratings_given: number;
  logo_image_ref: string;
  ext_user_image: string;
  user_image: string;
  first_name: string;
  ratings_received: number;
  work_experience: [];
  rating_value: number;
  phone: string;
  last_name: string;
  allow_receive_rewards: boolean;
  user_internal_id: string;
  business: Business[];
  role_id: number;
  hide_manager: boolean;
  manager_email_for_team: boolean;
  teams: TeamModel[];
  experiences: Experience[];

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    this.id = obj.id || 0;
    this.show_full_name = obj.show_full_name || false;
    this.email = obj.email || "";
    this.job_title = obj.job_title || "";
    this.biography = obj.biography || "";
    this.cover_image_ref =
      obj.cover_image_ref || "assets/img/placeholder_user@3x.png";
    this.ratings_given = obj.ratings_given || 0;
    this.logo_image_ref =
      obj.logo_image_ref || "assets/img/placeholder_user@3x.png";
    this.ext_user_image =
      (obj.logo_image_ref && environment.awsStorageUrl + obj.logo_image_ref) ||
      "";
    this.first_name = obj.first_name || "";
    this.ratings_received = obj.ratings_received || 0;
    this.work_experience = obj.work_experience || [];
    this.rating_value = obj.rating_value || 0;
    this.phone = obj.phone || "";
    this.last_name = obj.last_name || "";
    this.allow_receive_rewards = obj.allow_receive_rewards || false;
    this.user_internal_id = obj.user_internal_id || "";
    this.business = [];
    if (obj.business) {
      obj.business.forEach((business) => {
        this.business.push(new Business(business));
      });
    }
    this.user_image = obj.user_image || "";
    this.role_id = obj.role_id || 4;
    this.hide_manager = obj.hide_manager || false;
    this.manager_email_for_team = obj.manager_email_for_team || false;
    this.teams = obj.teams || [];
    this.experiences = [];
    if (obj.experiences && obj.experiences.length) {
      obj.experiences
        .sort((a, b) => {
          if (a.start_date < b.start_date) {
            return 1;
          }
          if (a.start_date > b.start_date) {
            return -1;
          }
          if (!a.end_date || (!!b.end_date && a.end_date < b.end_date)) {
            return 1;
          }
          if (!b.end_date || (!!a.end_date && b.end_date < a.end_date)) {
            return -1;
          }
          return 0;
        })
        .forEach((experience) => {
          this.experiences.push(new Experience(experience));
        });
      this.job_title = this.experiences[0].job_title;
      //this.job_name = this.experiences[0].name;
    }
  }

  get rating() {
    return Math.round(this.rating_value * 10) / 10;
  }
}
