import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, finalize } from 'rxjs';
import { URLS, VALUES } from '../utils/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private returnUrl: string;
  public afterLoginError = false;

  private isLoggedOut: string;
  public showLoginButton = false;
  public performingAfterLogin = false;

  constructor(
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'directory';
    this.isLoggedOut = this.route.snapshot.queryParams['logout'];

    this.auth.user$.subscribe(
      (profile) => {
        if (profile) {
          if (this.userService.isLoggedIn.value || this.performingAfterLogin) {
            return;
          }

          this.performingAfterLogin = true;

          this.userService.afterAuth0Login(profile)
            .pipe(
              finalize(() => this.performingAfterLogin = false),
              catchError((err) => {
                this.afterLoginError = true;
                throw err;
              })).subscribe(
                () => {
                  this.afterLoginError = false;
                  this.router.navigateByUrl(this.returnUrl, { replaceUrl: true });
                }
              );
        }
      }
    );

    this.auth.isAuthenticated$.subscribe(
      (loggedIn) => {
        if (!loggedIn && this.isLoggedOut !== VALUES.TRUE) {
          this.auth.loginWithRedirect();
        } else {
          this.showLoginButton = (this.isLoggedOut === VALUES.TRUE);
        }

        if(this.showLoginButton) {
          this.auth.loginWithRedirect()
        }
      }
    );
  }
}
