<div class="page-login page-forgotPass clearfix">
  <div class="leftSidePanel">
    <img src="assets/img/logo-v3.png" alt="Logo Image" class="logoImg" />

    <h1 [ngClass]="{ error: error, success: !error }" *ngIf="message">
      {{ message }}
    </h1>

    <a [routerLink]="['/login']" class="link-forgotPass">Login</a>
  </div>
  <div class="rightSidePanel"></div>
</div>
