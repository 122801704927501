import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class Maintenance implements CanActivate {
  constructor(private user: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //return true if not in maintenance mode
    return true;
    this.router.navigate(["/maintenance"], {
      queryParams: { returnUrl: state.url },
      replaceUrl: true,
    });
    return false;
  }
}
