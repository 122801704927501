import { UserInfo } from "./user-info.model";
import { User } from "./user.model";
export class MostRecent {
  public readonly RATING_OH_WELL = 1.5;
  public readonly RATING_GOOD = 3;
  public readonly RATING_GREAT = 4;
  public readonly RATING_EXCELENT = 5;

  rating: {
    rating_comment: string;
    capture_date: string;
    rating_value: number;
    user_id: string;
    reported_by: number;
  };

  user_info: UserInfo;
  rating_user_info: UserInfo;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    this.rating = {
      ...{
        rating_comment: "",
        capture_date: "",
        rating_value: 0,
        user_id: "",
      },
      ...obj,
    };
    this.user_info = new UserInfo(obj.employee);
    this.rating_user_info = new UserInfo(obj.author);
  }

  get image(): string {
    const path = "assets/img/";
    switch (this.rating.rating_value) {
      case this.RATING_OH_WELL:
        return path + "oh_well.svg";
      case this.RATING_GOOD:
        return path + "good.svg";
      case this.RATING_GREAT:
        return path + "great.svg";
      case this.RATING_EXCELENT:
      default:
        return path + "excelent.svg";
    }
  }

  get imageAlt(): string {
    const path = "assets/img/";
    switch (this.rating.rating_value) {
      case this.RATING_OH_WELL:
        return "oh well";
      case this.RATING_GOOD:
        return "good";
      case this.RATING_GREAT:
        return "great";
      case this.RATING_EXCELENT:
      default:
        return "excellent";
    }
  }

  get date(): string {
    let capture_date = new Date(this.rating.capture_date);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return capture_date.getDate() + " " + months[capture_date.getMonth()];
  }

  get fullDate(): string {
    let capture_date = new Date(this.rating.capture_date);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return capture_date.getDate() + " " + months[capture_date.getMonth()] + ", " + capture_date.getFullYear();
  }

  get time(): string {
    let gmt_date = new Date(this.rating.capture_date);
    let capture_date = new Date(
      Date.UTC(
        gmt_date.getFullYear(),
        gmt_date.getMonth(),
        gmt_date.getDate(),
        gmt_date.getHours(),
        gmt_date.getMinutes(),
        gmt_date.getSeconds()
      )
    );
    var hours = capture_date.getHours();
    var minutes = capture_date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let strMinutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + strMinutes + " " + ampm;
    return strTime;
  }
}
