<app-loader></app-loader>

<grata-navigation  *ngIf="configuration" [configuration]="this.configuration" [logoUrl]="'../assets/icon/grata-logo.svg'" (onLogin)="login()" (onLogout)="userService.logout()" (onSearch)="searchNavigation($event)"  (onRedirect)="redirect($event)">
  <div class="left-side mt-4 mx-4 flex">
      <mat-select class="shadow-sm appearance-none border-2 border-grata-gray-300 rounded-lg px-3 pt-2 pb-2 bg-grata-white text-grata-blue-900 font-medium leading-normal flex justify-between align-center w-96"
        (selectionChange)="onLocationChange($event)"
        [(value)]="business.id">
        <mat-option [value]="location.id" *ngFor="let location of locations"><small>{{location.brand_name}} - {{location.address_1}} {{location.city}}, {{location.state}} {{location.zip}} </small></mat-option>
      </mat-select>
  </div>
  <router-outlet></router-outlet>
</grata-navigation>
