export class MostRatedEmployeeModel {
    id: number;
    first_name: string;
    last_name: string;
    logo_image_ref: string;
    show_full_name: boolean;
    job_title: string;

    constructor(obj?) {
        if (!obj) {
            return;
        }
        this.id = obj.id || null;
        this.first_name = obj.first_name || '';
        this.last_name = obj.last_name || '';
        this.job_title = obj.job_title || '';
        this.show_full_name = obj.show_full_name || true
        this.logo_image_ref = obj.logo_image_ref || '/assets/img/Avatar-Placeholder.png';

    }
}
