import { environment } from "../../environments/environment";
export class Rating {
  public readonly RATING_OH_WELL = 1.5;
  public readonly RATING_GOOD = 3;
  public readonly RATING_GREAT = 4;
  public readonly RATING_EXCELENT = 5;

  user: {
    id: string;
    name: string;
    first_name: string;
    last_name: string;
    ext_user_image: string;
    job_title: string;
    logo_image_ref: string;
  };

  capture_date: string;
  rating_value: number;
  rating_comment: string;
  reported_by: number;
  rating_image_ref: string;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    const first_name = (obj && obj.author && obj.author.first_name) || "";
    const last_name = (obj && obj.author && obj.author.last_name) || "";
    this.user = {
      id: (obj.author && obj.author.id) || "",
      first_name,
      last_name,
      name: first_name + " " + last_name,
      ext_user_image:
        (obj.author &&
          obj.author.logo_image_ref &&
          environment.awsStorageUrl + obj.author.logo_image_ref) ||
        "",
      logo_image_ref: (obj.author && obj.author.logo_image_ref) || "",

      job_title: (obj.author && obj.author.job_title) || "",
    };

    this.capture_date = obj.capture_date || "";
    this.rating_value = obj.rating_value || 0;
    this.rating_comment = obj.rating_comment || "";
    this.reported_by = obj.reported_by || null;
    this.rating_image_ref = obj.rating_image_ref || "";
  }

  get date(): string {
    const capture_date = new Date(this.capture_date);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return capture_date.getDate() + " " + months[capture_date.getMonth()];
  }

  get time(): string {
    let gmt_date = new Date(this.capture_date);
    let capture_date = new Date(
      Date.UTC(
        gmt_date.getFullYear(),
        gmt_date.getMonth(),
        gmt_date.getDate(),
        gmt_date.getHours(),
        gmt_date.getMinutes(),
        gmt_date.getSeconds()
      )
    );
    var hours = capture_date.getHours();
    var minutes = capture_date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let strMinutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + strMinutes + " " + ampm;
    return strTime;
  }

  get timestamp(): number {
    return new Date(this.capture_date).getTime();
  }

  get image(): string {
    const path = "assets/img/";
    switch (this.rating_value) {
      case this.RATING_OH_WELL:
        return path + "oh_well.svg";
      case this.RATING_GOOD:
        return path + "good.svg";
      case this.RATING_GREAT:
        return path + "great.svg";
      case this.RATING_EXCELENT:
      default:
        return path + "excelent.svg";
    }
  }

  get imageAlt(): string {
    const path = "assets/img/";
    switch (this.rating_value) {
      case this.RATING_OH_WELL:
        return "oh well";
      case this.RATING_GOOD:
        return "good";
      case this.RATING_GREAT:
        return "great";
      case this.RATING_EXCELENT:
      default:
        return "excelent";
    }
  }
}

export class Ratings {
  totalCount: number;
  ratings: Rating[] = [];

  constructor(obj: any) {
    if (!obj) {
      return;
    }

    this.totalCount = obj.count || 0;
    this.ratings = obj.rows?.map(item => new Rating(item));
  }
}
