import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Maintenance } from './services/maintenance.service';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './login/components/forgot-password/forgot-password.component';
import { SetAccountComponent } from './login/components/set-account/set-account.component';
import { ConfirmChangeEmailComponent } from './login/components/confirm-change-email/confirm-change-email.component';
import { ConfirmEmailComponent } from './login/components/confirm-email/confirm-email.component';
import { ResetLoginPasswordModule } from './reset-login-password/reset-login-password.module';
import { AuthGuard } from "@auth0/auth0-angular";

const routes: Routes = [
  { path: "", redirectTo: "/directory", pathMatch: "full" },
  { path: "maintenance", component: MaintenanceComponent },
  { path: "login", canActivate: [Maintenance], component: LoginComponent },
  { path: "login?logout=true", pathMatch: "full" },
  {
    path: "forgot",
    canActivate: [Maintenance],
    component: ForgotPasswordComponent,
  },
  {
    path: "set-account/:token",
    canActivate: [Maintenance],
    component: SetAccountComponent,
  },
  {
    path: "confirm-change-email/:token",
    canActivate: [Maintenance],
    component: ConfirmChangeEmailComponent,
  },
  {
    path: "confirm-email/:token",
    canActivate: [Maintenance],
    component: ConfirmEmailComponent,
  },
  {
    path: "reset",
    canActivate: [Maintenance],
    loadChildren: () => ResetLoginPasswordModule,
  },
  {
    path: "directory",
    loadChildren: () =>
      import("./directory/directory.module").then(
        (m) => m.DirectoryModule
      ),
    canActivate: [Maintenance, AuthGuard],
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./settings/settings.module").then(
        (m) => m.SettingsModule
      ),
      canActivate: [Maintenance, AuthGuard],
  },
  {
    path: "gratas",
    loadChildren: () =>
      import("./gratas/gratas.module").then(
        (m) => m.GratasModule
      ),
      canActivate: [Maintenance, AuthGuard],
  },
  {
    path: "groups",
    loadChildren: () =>
      import("./groups/groups.module").then(
        (m) => m.GroupsModule
      ),
      canActivate: [Maintenance, AuthGuard],
  },
  {
    path: "user",
    loadChildren: () =>
      import("./user/user.module").then(
        (m) => m.UserModule
      ),
      canActivate: [Maintenance, AuthGuard],
  },
  {
    path: "submit-rating",
    canActivate: [Maintenance],
    loadChildren: () =>
      import("./submit-rating/submit-rating.module").then(
        (m) => m.SubmitRatingModule
      ),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
