<div *ngIf="this.loader.getLoading()" class="flex justify-center items-center cssload-container">
  <div class="flex flex-col items-center justify-center">
    <img class="h-12 w-auto" src="assets/img/logo-v1.png" alt="Workflow" />
    <div class="flex items-center justify-center space-x-2 mt-2">
      <div class="w-2 h-2 rounded-full animate-pulse bg-grata-blue-300 dark:bg-grata-blue-500"></div>
      <div class="w-2 h-2 rounded-full animate-pulse bg-grata-blue-300 dark:bg-grata-blue-500"></div>
      <div class="w-2 h-2 rounded-full animate-pulse bg-grata-blue-300 dark:bg-grata-blue-500"></div>
    </div>
  </div>
  <span class="sr-only">Loading...</span>
  <div class="">{{text}}</div>
</div>
