import { environment } from "../../environments/environment";
export class UserInfo {
  uid: number;
  first_name: string;
  last_name: string;
  job_title: string;
  rating_value: number;
  user_image: string;
  createdAt: string;
  show_full_name: any;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    this.uid = obj.id || 0;
    this.first_name = obj.first_name || "";
    this.last_name = obj.last_name || "";
    this.job_title =
      (obj.experiences && obj.experiences[0] && obj.experiences[0].job_title) ||
      "";
    this.rating_value = obj.rating_value || 0;
    this.user_image =
      (obj.logo_image_ref && environment.awsStorageUrl + obj.logo_image_ref) ||
      "";
    this.createdAt = obj.createdAt || "";
    this.show_full_name = obj.show_full_name || false;
  }

  get rating() {
    return Math.round(this.rating_value * 10) / 10;
  }
}
