import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Business } from '../models/business.model';
import { BusinessMeta } from '../models/businessMeta.model';
import { MostRatedEmployeeModel } from '../models/mostRatedEmployee';
import { IUpdateBusinessMeta, IUpdateBusinessMetaResponse } from '../models/update-business-meta.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private http: HttpClient, private userService: UserService) { }

  getBusiness(businessId: any) {
    return this.http
      .get<{ res: object }>(
        environment.api + "business/" + businessId + "/details"
      )
      .pipe(
        map((response) => {
          return new Business(response);
        })
      );
  }

  getMostRatedEmployees(businessId: any) {
    return this.http
      .get<Array<{}>>(
        environment.api + "business/" + businessId + "/most-rated-employees"
      )
      .pipe(
        map((response) => {
          return response.map((item) => { return new MostRatedEmployeeModel(item); });
        })
      );
  }
  getBusinessMeta(businessId: any) {
    return this.http
      .get<Array<BusinessMeta>>(
        environment.api + "business/" + businessId + "/meta-values"
      )
      .pipe(
        map((response: BusinessMeta[]) => {
          let metaObj = {};
          response.map((item: BusinessMeta) => {
            metaObj[item.meta_key] = item.meta_value; return;
          });
          return metaObj;
        })
      );
  }

  public updateBusinessMeta(request: any, businessId: number): Observable<any> {
    const formData = new FormData();

    formData.append('brand_name', request.brand_name?.trim() || '');
    formData.append('address_1', request.address_1?.trim() || '');
    formData.append('city', request.city?.trim() || '');
    formData.append('state', request.state?.trim() || '');
    formData.append('zip', request.zip?.trim() || '');
    formData.append('email', request.email?.trim() || '');
    formData.append('website', request.website?.trim() || '');
    formData.append('company_description', request.company_description?.trim() || '');
    formData.append('career_opportunities', request.career_opportunities?.trim() || '');
    formData.append('is_display_email', request.is_display_email ? 'true' : 'false');
    formData.append('is_display_phone', request.is_display_phone ? 'true' : 'false');
    formData.append('twitter', request.twitter?.trim() || '');
    formData.append('instagram', request.instagram?.trim() || '');
    formData.append('facebook', request.facebook?.trim() || '');
    formData.append('youtube', request.youtube?.trim() || '');

    if (request.logo_image_ref instanceof File) {
      formData.append('logo_image_ref', request.logo_image_ref);
    }

    if (request.company_logo instanceof File) {
      formData.append('company_logo', request.company_logo);
    }

    return this.http.post(
      `${environment.api}business/meta/${businessId}/update`,
      formData) as Observable<any>;
  }

  sendMessageToManager(data: any) {

    return this.http
      .put<{ res: object }>(
        environment.api + "business/send-to-managers",
        data
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getBusinessEmployeeStatus(businessId: any) {
    return this.http
      .get<Array<any>>(
        environment.api + "business/" + businessId + "/status"
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // getGooglePlacesList() {
  //   const body = {
  //     "longitude": "33.14731380861324",
  //     "latitude":  "-96.7322462506944"
  //   }
  //   return this.http.post<Array<any>>('https://dev-api.gratapro.com/api/' + "business/nearby-list", body)
  //   .pipe(map((response) => { return response }))
  // }

  getBusinessAdmins(businessId) {
    const endpoint = `${environment.api + "business/" + businessId + "/admins"}`
    return this.http.get<Array<any>>(endpoint).pipe(map((response) => { return response}))
  }

  updateBusiness(businessInfo: any) {
    // console.log('Business info for update', businessInfo)
    return this.http
      .put<any>(
        environment.api + "business/" + businessInfo.id + "/update",
        businessInfo
      )
      .pipe(
        map((response) => {
          // localStorage.setItem(
          //   "selectedBusiness",
          //   JSON.stringify(businessInfo)
          // );
          // this.userService.selectedBusiness.next(businessInfo);
          return response;
        })
      );
  }

  businessShareUrl(businessId: any) {
    return this.http
      .get<any>(
        environment.api + "business/" + businessId + "/share-url"
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }


}
