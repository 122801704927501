export const STRINGS = {
  ACCOUNT_TRANSFER_SUCCESS: "Business account ownership transferred successfully.",
  DEFAULT_ERROR_MESSAGE: "Something went wrong!",
  TRY_AGAIN: "Something went wrong. Please try again later.",
  ERROR_OCCURRED: "Error occured.",
  COPIED_TO_CLIPBOARD: "is copied to the clipboard!",
  NEW_EMAIL_SAVED: "The new email address was saved",
  EMAIL_VERIFIED: "Email has been verified.",
  CANNOT_CHANGE_WHILE_EDITING: "Cannot change company while editing it",
  CHANGES_UPDATED: "Changes updated successfully!",
  INVITATION_SUCCESS: "Invite Successful",
  INVITATION_RESENT: "Invitation resent successfully",
  INVITATION_DELETED: "Invitation deleted successfully",
  RESET_INSTRUCTIONS_SENT: "E-mail sent with reset instructions",
  USER_UPDATE_SUCCESS: "User update success.",
  NEW_PASSWORD_SAVED: "New Password was saved",
  NEW_PASSWORD_SET: "New Password set",
  USER_UPDATED: "User profile updated successfully.",
  IDENTITY_SMS_SENT: "We just sent you an SMS to confirm your identity.",
  MESSAGE_SENT: "Message sent",
  GOOGLE_REVIEW_SITE_EXISTS: "Google review site already exists.",
  TRIPADVISOR_REVIEW_SITE_EXISTS: "TripAdvisor review site already exists",
  THANKS_FOR_YOUR_MESSAGE: 'Thanks for your message!'
}

export const VALUES = {
  SUCCESS: "Success",
  TRUE: "true",
  FALSE: "false",
  CANCEL: "Cancel",
  DELETE: "Delete",
  RESEND: "Resend",
  NAME: "name",
  DATE: "date",
  RATING: "rating",
  COMMENT: "comment",
  EMPLOYEES: "employees",
  GRATAS: "gratas",
  REPORTS: "reports",
  DEFAULT_CARD_WIDTH: "60%",
  CSV: ".csv",
  DASHBOARD_CSV: "dashboardGratas_.csv",
  OH_WELL: "AN OH WELL",
  GOOD: "A GOOD",
  GREAT: "A GREAT",
  EXCELLENT: "AN EXCELLENT",
  RATING_LIST: "RatingList-",
  MESSAGE_SENT: "messageSent",
  RESENT_CODE: "resent code",
  VALID_CODE: "valid code",
  WITHOUT_GIVER_IDENTITY: "without confirm grata giver identity",
  RATING_CREATED:"rating created",
  TEAM_UPDATED: "Team updated successfully.",
  TEAM_DELETED: "Team deleted successfully.",
}

export const NAMES = {
  EMPLOYEES: "Employees",
  GRATAS: "Gratas",
  REPORTS: "Reports",
  TEAM: "Team",
  ADMIN: "Admin",
  INVITED: "Invited",
  USER: "User",
  PHONE: "Phone",
  GOOGLE: "Google",
  TRIPADVISOR: "TripAdvisor"
}

export const TYPES = {
  STRING: "string",
  BLOB_CSV: "text/csv;charset=utf-8;",
  APP_JSON: 'application/json',
  QR: 'qr.zip'
}

export const ROLES = {
  ACCOUNT_OWNER: "Account Owner",
  SUPER_ADMIN: "Super Admin",
  ADMIN: "Admin",
  EMPLOYEE: "Employee"
}

export const TABLE_COLUMNS = {
  ACCOUNT: {
    ADMIN: ["name", "role", "teams", "actions"],
    REVIEW_URLS: ["review_site", "url", "actions"],
    CARD_INSTRUCTIONS:["title", "description", "url", "action", "actions"]
  },
  DASHBOARD: {
    LEADERBOARD: ['name', 'gratas', 'email', 'phone', 'status', 'view', 'actions'],
    REPORTS: ['date', 'name', 'rating', 'comment']
  }
}

export const ARRAYS = {
  REVIEW_SITES: ["Google", "TripAdvisor"]
}

export const URLS = {
  TWITTER: "twitter.com",
  INSTAGRAM: "instagram.com",
  FACEBOOK: "facebook.com",
  YOUTUBE: "youtube.com",
  DASHBOARD: "/dashboard/",
  LEADERBOARD: "/dashboard/leaderboard",
  REPORTS: "/dashboard/reports",
  CONFIRM_IDENTITY: "submit-rating/confirm-identity/",
  SUBMIT_RATING_EMPLOYEE: "submit-rating?employee="
}

export const USER_STATUS = {
  active: 'Active',
  inactive: 'Inactive',
  invited: 'Invited'
}
