import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ResetLoginPasswordRoutingModule } from "./reset-login-password-routing.module";
import { ResetComponent } from "./reset/reset.component";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ResetComponent],
  imports: [CommonModule, ResetLoginPasswordRoutingModule, ReactiveFormsModule],
})
export class ResetLoginPasswordModule {}
