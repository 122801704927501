import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../../services/user.service";
import { map } from "rxjs/operators";
import { STRINGS } from "../../../utils/constants";

@Component({
  selector: "app-confirm-change-email",
  templateUrl: "./confirm-change-email.component.html",
  styleUrls: ["./confirm-change-email.component.scss"],
})
export class ConfirmChangeEmailComponent implements OnInit {
  public message: string;
  public loading = false;
  public error = false;
  public token = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {
    route.params.pipe(map((p) => p.token)).subscribe((token) => {
      this.token = token;
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.message = "";

    this.userService.confirmChangeEmail(this.token).subscribe(
      (message) => {
        this.error = false;
        this.message = STRINGS.NEW_EMAIL_SAVED;
        this.loading = false;
      },
      (response) => {
        this.error = true;
        this.message =
          (response.error && response.error.error_message) || STRINGS.ERROR_OCCURRED;
        this.loading = false;
      }
    );
  }
}
