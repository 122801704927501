import { HttpHeaders } from "@angular/common/http";

export const environment = {
  production: true,
  api: "https://api3.grata.app/v3/api/",
  altApi: "https://prodapi3.grata.app/api/",
  xApiKey: "wxAG6QaNhpJ1rbX5ZhDH6Lq6wYMRpk88yTf4F64h",
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-api-key": "wxAG6QaNhpJ1rbX5ZhDH6Lq6wYMRpk88yTf4F64h",
    "Access-Control-Allow-Origin":"*"
  }),
  google_client_id:
    "554667752110-6pelr1qgcui2espd7s5acm33vu7e2603.apps.googleusercontent.com",
  facebook_client_id: "194431235281075",
  client_secret: "QnUvTsSTcYqgKrZIx1oIgnOfkPoJ53Lz",
  awsStorageUrl: "https://trs-grata-prod-media.s3.amazonaws.com/",
  auth0Domain: "grata.us.auth0.com",
  auth0ClientId: "rCRg7XoYmXO6D11WRcmhCWVVRYaB8zKG",
  // auth0RedirectUrl: "http://localhost:4200/login",
  auth0RedirectUrl: "https://admin.grata.app/login",
  auth0Audience: "https://api3.grata.app/v3/api/",
  auth0LogoutUrl: "https://admin.grata.app/directory",
 /* recaptcha: {
    siteKey: "6LdXA8IgAAAAAHbygRZSz51EuM63WUzoToyJk1a4",
  },*/
  siteUrl: 'https://admin.grata.app/',
  publicPages: "https://grata.app/",
};
export const navConfig = {
  primaryColor: '',
  secondaryColor: '',
  sidebar: true,
  state:'AdminState',
  authDetails: {roleName:"ADMIN",token:localStorage.getItem('authToken')},
  apiUrl:"https://api.grata.app/",
  navigationUrls:{
    insightUrl:{path:"https://insights.grata.app/",inApp:false},
    adminUrl:{path:"https://admin.grata.app/",inApp:false},
    appUrl:{path:"https://grata.app/",inApp:false},
    profileUrl:{path:"https://grata.app/menu/profile",inApp:false},
    faqUrl:{path:"https://support.gratapro.com/knowledge",inApp:false},
  },
  adminUrls:{
    gratasUrl:{path:"/gratas",inApp:true},
    directoryUrl:{path:"/directory",inApp:true},
    groupUrl:{path:"/groups",inApp:true},
    settingUrl:{path:"/settings",inApp:true}
  }
}
