import { Experience } from './experience.model';
import { Rating } from './rating.model';
export class Resume {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    rating_value: number;
    job_title: string;
    job_name: string;
    ratings_received: number;
    experiences: Experience[];
    ratings: Rating[]

    constructor(obj?: any) {
        if (!obj) {
            return;
        }

        this.first_name =  obj.user.first_name || '';
        this.last_name =  obj.user.last_name || '';
        this.rating_value =  obj.user.rating_value || 0;
        this.job_title =  obj.job_title || '';
        this.ratings_received =  obj.user.ratings_received || 0;
        this.email =  obj.user.email || '';
        this.phone =  obj.user.phone || '';
        this.experiences = [];
        this.ratings = [];
        this.job_name = '';
        if (obj.user.experiences && obj.user.experiences.length) {
            obj.user.experiences.sort((a, b) => {
                if (a.start_date < b.start_date) {
                    return 1;
                }
                if (a.start_date > b.start_date) {
                    return -1;
                }
                if (!a.end_date || (!!b.end_date && a.end_date < b.end_date)) {
                    return 1;
                }
                if (!b.end_date || (!!a.end_date && b.end_date < a.end_date)) {
                    return -1;
                }
                return 0;
            }).forEach((experience) => {
                if(experience.is_current_job){
                    this.experiences.unshift(new Experience(experience));
                } else {
                    this.experiences.push(new Experience(experience));
                }
            });
            this.job_title = this.experiences[0].job_title;

            this.job_name = this.experiences[0].name;
        }
        if (obj.ratings && obj.ratings.rows && obj.ratings.rows.length) {
            obj.ratings.rows.forEach((rating) => {
                this.ratings.push(new Rating(rating));
            });
        }
    }

    get name() {
        if (!this.first_name || !this.last_name) {
            return '';
        }
        return this.first_name + ' ' + this.last_name;
    }

    get rating() {
        if (!this.rating_value) {
            return 'N/A';
        }
        return Math.round(this.rating_value * 10) / 10;
    }

    get ratingPercent(): number {
        if (!this.rating_value) {
            return 0;
        }
        return Math.round(this.rating_value * 10) * 2;
    }
}
