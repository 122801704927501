import { Resume } from "../models/resume.model";
import { Rating, Ratings } from "../models/rating.model";
import { UserProfile } from "../models/user-profile.model";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { LoginResponse } from "../Responses/login.response";
import { Business } from "../models/business.model";
import { Router } from "@angular/router";
import { User } from "../models/user.model";
import { environment } from "../../environments/environment";
import { map, distinctUntilChanged, filter, catchError } from "rxjs/operators";
import { AuthService } from "@auth0/auth0-angular";
import { MostRecent } from "../models/most-recent.model";
import { SendInviteTO } from "../models/employee.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

  public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSuperAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSupervisor: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public shouldShowPasswordReset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedBusiness: BehaviorSubject<Business> = new BehaviorSubject<Business>(new Business());

  constructor(
    private http: HttpClient,
    private router: Router,
    public auth: AuthService
  ) {
    let storedUser = localStorage.getItem("currentUser");
    if (!storedUser) return;
    let currentUser = new User(JSON.parse(storedUser));

    this.currentUser.next(currentUser);

    let storedBusiness = localStorage.getItem("selectedBusiness");
    if (!storedBusiness) {
      this.selectedBusiness.next(currentUser.business[0]);
    } else {
      let strdBusiness = undefined;
      try {
        strdBusiness = JSON.parse(storedBusiness);
      } catch (e) { }
      this.selectedBusiness.next(new Business(strdBusiness));
    }
    this.selectedBusiness
      .pipe(
        distinctUntilChanged(),
        filter((business) => {
          return !!business.id;
        })
      )
      .subscribe((business: Business) => {
        localStorage.setItem("selectedBusiness", JSON.stringify(business));
      });
    this.isLoggedIn.next(true);

    if (
      this.selectedBusiness.value &&
      this.selectedBusiness.value.user_business_role &&
      this.selectedBusiness.value.user_business_role.role_id >= 0
    ) {
      if (this.selectedBusiness.value.user_business_role.role_id <= 2) {
        this.isSuperAdmin.next(true);
      }
      if (this.selectedBusiness.value.user_business_role.role_id == 0) {
        this.isSupervisor.next(true);
      }
    }
  }

  private resolveLogin(result: any) {
    if (!(result.user_settings && result.user_settings.business)) {
      throw result;
    }
    const user = new User({
      ...result.user_settings,
      business: result.user_settings.business,
    });

    localStorage.setItem("token", result.token);

    this.currentUser.next(user);
    localStorage.setItem("currentUser", JSON.stringify(user));
    localStorage.setItem(
      "selectedBusiness",
      JSON.stringify(user.business[0])
    );
    this.selectedBusiness.next(user.business[0]);
    this.isLoggedIn.next(true);

    if (
      this.selectedBusiness.value &&
      this.selectedBusiness.value.user_business_role &&
      this.selectedBusiness.value.user_business_role.role_id >= 0
    ) {
      if (this.selectedBusiness.value.user_business_role.role_id <= 2) {
        this.isSuperAdmin.next(true);
      }

      if (this.selectedBusiness.value.user_business_role.role_id == 0) {
        this.isSupervisor.next(true);
      }
    }
    return user;
  }

  login(email: String, password: String) {
    return this.http
      .post<LoginResponse>(
        environment.api + "admin/login",
        {
          email: email,
          password: password,
        }
      )
      .pipe(
        map((result) => this.resolveLogin(result)),
        map((user) => {
          this.shouldShowPasswordReset.next(true);
          return user;
        })
      );
  }

  getProfileUser(uid: string) {
    return this.http.get<{ user: object }>(
      environment.api + "employee-public/" + uid + "/details"
    );
  }

  getProfile(uid: string) {
    return this.http
      .get<{ user: object }>(environment.api + "employee/" + uid + "/details")
      .pipe(map((user) => new UserProfile(user)));
  }
  getRating(gid: any) {
    return this.http
      .get<{ user: object }>(environment.api + "rating/" + gid + "/details")
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPublicProfile(uid: string) {
    return this.http
      .get<{ user: object }>(
        environment.api + "employee-public/" + uid + "/details")
      .pipe(map((user) => new UserProfile(user)));
  }
  updateUserProfile(userInfo: any) {
    return this.http
      .post<{ res: object }>(
        environment.api + "userUpdate/" + userInfo.id, userInfo)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getResume(uid: string) {
    return this.http
      .get(
        /*<{user: object}>*/ environment.api + "employee/" + uid + "/resume"
      )
      .pipe(
        map((result) => {
          let resume = new Resume(result);
          return resume;
        })
      );
  }

  getResumeDownloadUrl(uid: string) {
    return environment.api + "user/" + uid + "/resume";
  }

  updateExperience(userInfo) {
    return this.http
    .post<{ res: object }>(
      environment.api + "user/update-experience", userInfo)
    .pipe(
      map((response) => {
        return response;
      })
    );
  }

  // getRatings(
  //   uid: string,
  //   rating: number = 0,
  //   page: number = 1,
  //   pageLimit: number = 10
  // ): Observable<any> {
  //   return this.http
  //     .post(
  //       /*<{ratings: []}>*/ environment.api +
  //       "employee/" +
  //       uid +
  //       "/ratings-list",
  //       {
  //         /*user_id: uid,*/
  //         rating_value: rating,
  //         page: page,
  //         pageLimit: pageLimit,
  //       }
  //     )
  //     .pipe(
  //       map((response: any) => {
  //         if (response && response.rows) {
  //           response.rows = response.rows.map((userRatings) => {
  //             return new Rating(userRatings);
  //           });
  //         }

  //         return response;
  //       })
  //     );
  // }
  getCompanyPageRatings(
    uid: string,
    rating: number = 0,
    page: number = 1,
    pageLimit: number = 10
  ): Observable<Rating[]> {
    return this.http
      .post(
        /*<{ratings: []}>*/ environment.api +
        "employee/" +
        uid +
        "/public-ratings-list",
        {
          /*user_id: uid,*/
          rating_value: rating,
          page: page,
          pageLimit: pageLimit,
        }
      )
      .pipe(
        map((response: any) => {
          if (response && response.rows) {
            response.rows = response.rows.map((userRatings) => {
              return new MostRecent(userRatings);
            });
          }
          return response;
        })
      );
  }

  externalAuth(data: any) {
    return this.http
      .post<LoginResponse>(environment.api + "admin/social-auth", data)
      .pipe(map((result) => this.resolveLogin(result)));
  }


  forgot(email: string) {
    return this.http.post(
      environment.api + "user/request-password-change",
      {
        email: email,
      }
    );
  }

  verifyPasswordCode(token: string) {
    return this.http.post(
      environment.api + "user/verify-password-code",
      {
        token: token,
      }
    );
  }

  resetPassword(token: string, password: string, confirm_password: string) {
    return this.http.post(
      environment.api + "user/reset-password",
      {
        token: token,
        password: password,
        confirm_password: confirm_password,
      }
    );
  }

  setAccount(token: string, password: string, confirm_password: string) {
    return this.http.post(
      environment.api + "user/set-account",
      {
        token: token,
        password: password,
        confirm_password: confirm_password,
      }
    );
  }

  confirmChangeEmail(token: string) {
    return this.http.post(
      environment.api + "user/confirm-change-email",
      {
        token: token,
      }
    );
  }

  confirmEmail(token: string) {
    return this.http.post(
      environment.api + "user/verify-email",
      {
        token: token,
      }
    );
  }

  changePassword(email: string, old_password: string, new_password: string) {
    return this.http.post(
      environment.api + "passwordUpdate",
      {
        email: email,
        old_password: old_password,
        new_password: new_password,
      }
    );
  }

  logout() {
    localStorage.clear()
    this.auth.logout({
      logoutParams: {
        returnTo: environment.auth0LogoutUrl
      }
    });
  }

  changeUserPassword(old_password: string, new_password: string) {
    return this.http
      .post<{ res: object }>(
        environment.api + "user/change-password",
        {
          password: old_password,
          new_password: new_password,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  changeUserPasswordWithAuth0() {
    return this.http
      .post<{ res: object }>(
        environment.api + "user/change-password",
        {}
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAdminEmployee(user_id: number) {
    return this.http
      .get<{ res: object }>(
        environment.api + "employee/" + user_id + "/details"
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getBusinessTeams(business_id: number) {
    return this.http
      .get<any>(
        environment.api + "business/" + business_id + "/teams"
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getBusinessGroups(info: any) {
    return this.http
      .post<any>(
        environment.api + "business/get-teams",
        info
      )
      .pipe(
        map((response) => {
          // localStorage.setItem('selectedBusiness', JSON.stringify(userInfo));
          // this.selectedBusiness.next(userInfo);
          return response;
        })
      );
  }

  updateBusinessLabels(businessInfo: any) {
    return this.http
      .put<{ res: object }>(
        environment.api + "business/" + businessInfo.id + "/edit",
        businessInfo
      )
      .pipe(
        map((response) => {
          localStorage.setItem(
            "selectedBusiness",
            JSON.stringify(businessInfo)
          );
          this.selectedBusiness.next(businessInfo);
          return response;
        })
      );
  }

  deleteUserAccount(userInfo: any) {
    return this.http
      .post<{ res: object }>(
        environment.api + "admin/" + userInfo.user_id + "/delete",
        userInfo
      )
      .pipe(
        map((response) => {
          // localStorage.setItem('selectedBusiness', JSON.stringify(userInfo));
          // this.selectedBusiness.next(userInfo);
          return response;
        })
      );
  }
  createAdminUser(user: User, business_id: any) {
    return this.http
      .post<{ res: object }>(
        environment.api + "admin/create-user",
        { ...user, business_id: business_id }
      )
      .pipe(
        map((response) => {
          // localStorage.setItem('selectedBusiness', JSON.stringify(user));
          // this.selectedBusiness.next(user);
          return response;
        })
      );
  }

  updateAdminUser(user: User, business_id: any) {
    return this.http
      .post<{ res: object }>(
        environment.api + "admin/" + user.id + "/update",
        { ...user, business_id: business_id }
      )
      .pipe(
        map((response) => {
          // localStorage.setItem('selectedBusiness', JSON.stringify(user));
          // this.selectedBusiness.next(user);
          return response;
        })
      );
  }
  transferToAccountOwner(oldOwner: any, newOwner: any, businessId: any) {
    return this.http
      .post<{ res: object }>(
        environment.api + "admin/transfer-ownership",
        { user_id: newOwner, business_id: businessId }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deactivateUser(userInfo: any) {
    // console.log('UserInfo for ending experience', userInfo)
    return this.http
      .post<{ res: object }>(
        environment.api + "admin/deactivate-user/",
        userInfo
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  reactivateUser(userInfo: any) {
    // console.log('UserInfo for reactivating experience', userInfo)
    return this.http
    .post<{ res: object }>(
      environment.api + "admin/reactivate-user/",
      userInfo
    )
    .pipe(
      map((response) => {
        return response;
      })
    );
  }

  afterAuth0Login(profile: any) {
    return this.http
      .post<LoginResponse>(environment.api + "admin/afterAuth0Login", profile)
      .pipe(
        map((result) => this.resolveLogin(result)),
        map((user) => {
          this.shouldShowPasswordReset.next(true);
          return user;
        }),
        catchError(err => {
          // Do not do user service logout because it will kill snack error due to redirect from Auth0.
          // console.log('afterAuth0Login error', err);
          this.logout()

          throw err;
        })
      );
  }


  public sendInvite(userInfo: SendInviteTO): Observable<any> {
    return this.http.post(`${environment.api}invitation/send`, userInfo);
  }

  public reSendInvite(businessId: number, invitationId: number): Observable<any> {
    const request = {
      businessId,
      invitationId
    };

    return this.http.post(`${environment.api}invitation/resend`, request);
  }

  public deleteInvite(businessId: number, invitationId: number): Observable<any> {
    const request = {
      businessId,
      invitationId
    };

    return this.http.post(`${environment.api}invitation/delete`, request);
  }

  addNewAdminUser(userData) {
    return this.http.post(`${environment.api}admin/add-user`, userData);
  }

  editAdminUser(userData) {
    return this.http.post(`${environment.api}admin/edit-user`, userData);
  }

  removeExistingAdminUser(userData) {
    return this.http.post(`${environment.api}admin/remove-user`, userData);
  }

  getRatings(
    uid: number,
    rating: number = 0,
    // page: number = 1,
    // pageLimit: number = 10
  ): Observable<any> {
    const request = {
      rating_value: rating,
      // page: page,
      // pageLimit: pageLimit
    };

    return this.http.post(
      `${environment.api}employee/${uid}/public-ratings-list`, request)
      .pipe(
        map((response: any) => response)
      );
  }
}
