import { Rating } from "./rating.model";
import { environment } from "../../environments/environment";

export class UserProfile {
  public readonly PAGE_SIZE = 10;
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  rating_value: number;
  job_title: string;
  ratings_given: number;
  ratings_received: number;
  ext_user_image: string;
  logo_image_ref: string;
  biography: string;
  businesses_ids: number[];
  businesses_original_ids: string[];
  experiences: any[];
  cover_image_ref: string;
  ratings: {
    oh_well: number;
    good: number;
    great: number;
    excelent: number;
  };
  role_id: number;
  allow_receive_rewards: boolean;
  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    let experience = null;
    if (obj.experiences && obj.experiences.length) {
      experience = obj.experiences.filter((exp) => {
        return exp && exp.is_current_job;
      });
    }
    if (obj.businesses && obj.businesses.length) {
      this.businesses_ids = obj.businesses.map((b) => {
        return b && b.id;
      });
      this.businesses_original_ids = obj.businesses.map((b) => {
        return b && b.original_id;
      });
    }

    this.id = obj.id || "";
    this.first_name = obj.first_name || "";
    this.last_name = obj.last_name || "";
    this.rating_value = obj.rating_value || 0;
    this.job_title =
      (experience && experience[0] && experience[0].job_title) || "";
    this.ratings_given = obj.ratings_given || 0;
    this.ratings_received = obj.ratings_received || 0;
    this.logo_image_ref = obj.logo_image_ref || "";
    this.ext_user_image =
      (obj.logo_image_ref && environment.awsStorageUrl + obj.logo_image_ref) ||
      "";
    this.biography = obj.biography || "";
    this.ratings = {
      oh_well: obj.ratings_oh_well || 0,
      good: obj.ratings_good || 0,
      great: obj.ratings_great || 0,
      excelent: obj.ratings_excelent || 0,
    };
    this.email = obj.email || "";
    this.phone = obj.phone || "";
    this.role_id = obj.role_id || 4;
    this.experiences = obj.experiences || [];
    this.cover_image_ref =
      (obj.cover_image_ref &&
        environment.awsStorageUrl + obj.cover_image_ref) ||
      "";
  }

  get name() {
    return this.first_name + " " + this.last_name;
  }

  get rating() {
    if (!this.rating_value) {
      return "N/A";
    }
    return Math.round(this.rating_value * 10) / 10;
  }
}
