import { Component, Input } from '@angular/core';
import { LoaderService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() text: string;
  constructor(public loader: LoaderService) { }
}
