<div class="page-login page-forgotPass clearfix">
  <div class="leftSidePanel">
    <img src="assets/img/logo-v3.png" alt="Logo Image" class="logoImg" />

    <h1>Set Your Password</h1>
    <form
      class="form form-default"
      [formGroup]="forgotForm"
      (ngSubmit)="onSubmit()"
    >
      <input
        formControlName="password"
        type="password"
        placeholder="YOUR NEW PASSWORD"
        class="cInput"
      />
      <input
        formControlName="confirm_password"
        type="password"
        placeholder="CONFIRM PASSWORD"
        class="cInput"
      />
      <p [ngClass]="{ error: error, success: !error }" *ngIf="message">
        {{ message }}
      </p>
      <input
        [disabled]="!forgotForm.valid || loading"
        type="submit"
        class="cBtn btn-blue btn-submit"
        value="{{ loading ? 'LOADING...' : 'SET PASSWORD' }}"
      />
      <a [routerLink]="['/login']" class="link-forgotPass">Login Instead</a>
    </form>
  </div>
  <div class="rightSidePanel"></div>
</div>
