import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon';
import { ToolbarHeaderComponent } from './components/toolbar-header/toolbar-header.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { DropdownDirective } from './directives/dropdown.directive';
import { DynamicDropdownComponent } from './components/dynamic-dropdown/dynamic-dropdown.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';


import { LogoComponent } from './components/logo/logo.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { CountryInputComponent } from './components/country-input/country-input.component';
import { FlagLocationPipe } from './components/country-input/flag-location.pipe';
import { HttpClientModule } from "@angular/common/http";
import { FormFieldComponent } from './components/form-field/form-field.component';
import { UserImageComponent } from './components/user-image/user-image.component';
import { HeaderComponent } from './components/header/header.component';
import { BusinessCardComponent } from './components/business-card/business-card.component';
import { RatingPipe } from './pipes/rating.pipe';
import { ImageLocationPipe } from './pipes/image-location.pipe';
import { DragDirective } from './directives/dragDropDirective';
import { DragDropUploadComponent } from './components/drag-drop-upload/drag-drop-upload.component';
import { ProgressComponent } from './components/progress/progress.component';
import { EmployeePanelComponent } from './components/employee-panel/employee-panel.component';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PersonNamePipe } from './pipes/person-name.pipe';
import { IsCurrentYearPipe } from './pipes/is-current-year.pipe';
import { ShareButtonComponent } from './components/share-button/share-button.component';
import { ShareThisOverlayComponent } from './components/share-this-overlay/share-this-overlay.component';
import { TooltipListPipe } from './pipes/tooltip-list.pipe';
import { SplitButtonComponent } from './components/split-button/split-button.component';
import { GrataCardComponent } from './components/grata-card/grata-card.component';
import { UserProfilePanelComponent } from './components/user-profile-panel/user-profile-panel.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { S3ImagePipe } from '../pipes/s3Image.pipe';

@NgModule({
  declarations: [
    LogoComponent,
    PageTitleComponent,
    TabsComponent,
    DropdownComponent,
    ToolbarHeaderComponent,
    AvatarComponent,
    DynamicTableComponent,
    DropdownDirective,
    DragDirective,
    DynamicDropdownComponent,
    SnackBarComponent,
    LoaderComponent,
    ValidationMessageComponent,
    PhoneInputComponent,
    CountryInputComponent,
    FlagLocationPipe,
    FormFieldComponent,
    UserImageComponent,
    HeaderComponent,
    BusinessCardComponent,
    RatingPipe,
    ImageLocationPipe,
    PersonNamePipe,
    IsCurrentYearPipe,
    DragDropUploadComponent,
    ProgressComponent,
    EmployeePanelComponent,
    ShareButtonComponent,
    ShareThisOverlayComponent,
    TooltipListPipe,
    SplitButtonComponent,
    GrataCardComponent,
    UserProfilePanelComponent,
    OverlayComponent,
    PaginatorComponent,
    S3ImagePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    MatCheckboxModule,
    MatMenuModule,
    MatFormFieldModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatFormFieldModule,
    MatListModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatChipsModule,
    LogoComponent,
    PageTitleComponent,
    TabsComponent,
    DropdownComponent,
    AvatarComponent,
    DynamicTableComponent,
    DynamicDropdownComponent,
    SnackBarComponent,
    LoaderComponent,
    ValidationMessageComponent,
    PhoneInputComponent,
    CountryInputComponent,
    FormFieldComponent,
    UserImageComponent,
    HeaderComponent,
    DragDropUploadComponent,
    ShareButtonComponent,
    GrataCardComponent,
    UserProfilePanelComponent,
    PaginatorComponent,
    DropdownDirective,
    DragDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    FlagLocationPipe,
    RatingPipe,
    ImageLocationPipe,
    PersonNamePipe,
    IsCurrentYearPipe,
    TooltipListPipe,
    S3ImagePipe
  ],
  providers: [ provideNgxMask() ]
})

export class SharedModule { }
