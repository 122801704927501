import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { map } from "rxjs/operators";
import { STRINGS } from "../../../utils/constants";

@Component({
  selector: "app-set-account",
  templateUrl: "./set-account.component.html",
  styleUrls: ["./set-account.component.scss"],
})
export class SetAccountComponent implements OnInit {
  public forgotForm: UntypedFormGroup;
  public message: string;
  public loading = false;
  public error = false;
  public token = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    fb: UntypedFormBuilder
  ) {
    route.params.pipe(map((p) => p.token)).subscribe((token) => {
      this.token = token;
    });

    this.forgotForm = fb.group({
      password: ["", Validators.required],
      confirm_password: ["", Validators.required],
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    this.loading = true;
    this.message = "";
    const password = this.forgotForm.value.password;
    const confirm_password = this.forgotForm.value.password;
    this.userService
      .setAccount(this.token, password, confirm_password)
      .subscribe(
        () => {
          this.error = false;
          this.message = STRINGS.NEW_PASSWORD_SET;
          this.loading = false;
        },
        (response) => {
          this.error = true;
          this.message =
            (response.error && response.error.error_message) ||
            STRINGS.ERROR_OCCURRED;
          this.loading = false;
        }
      );
  }
}
