import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";

import { AuthModule } from "@auth0/auth0-angular";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthHttpInterceptor } from "@auth0/auth0-angular";
/*import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
  // RECAPTCHA_SETTINGS,
  // RecaptchaSettings,
} from "ng-recaptcha"; */
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './login/login.component';
import { environment } from "../environments/environment";
import { httpInterceptorProviders } from "./http-interceptors";
import { DestructiveDialogComponent } from './dialogs/destructive-dialog/destructive-dialog.component';
import { QrCodeDialogComponent } from './dialogs/qr-code-dialog/qr-code-dialog.component';
import { ResetPasswordComponent } from './login/components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './login/components/forgot-password/forgot-password.component';
import { ConfirmEmailComponent } from './login/components/confirm-email/confirm-email.component';
import { ConfirmChangeEmailComponent } from './login/components/confirm-change-email/confirm-change-email.component';
import { SetAccountComponent } from './login/components/set-account/set-account.component';
import { SendInviteDialogComponent } from './dialogs/send-invite-dialog/send-invite-dialog.component';
import { RemoveAdminDialogComponent } from './dialogs/remove-admin-dialog/remove-admin-dialog.component';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { BottomSheetComponent } from './submit-rating/components/bottom-sheet/bottom-sheet.component';
import { NavigationModule} from 'grata-navigation';


export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DestructiveDialogComponent,
    QrCodeDialogComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ConfirmEmailComponent,
    ConfirmChangeEmailComponent,
    SetAccountComponent,
    SendInviteDialogComponent,
    RemoveAdminDialogComponent,
    BottomSheetComponent,
  ],
  imports: [
    LottieModule.forRoot({ player: playerFactory }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    RouterModule,
    SharedModule,
//    RecaptchaV3Module,
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      authorizationParams: {
        audience: environment.auth0Audience,
        redirect_uri: environment.auth0RedirectUrl
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.api}*`,
            allowAnonymous: true,
          },
        ],
      }
    }),
    NavigationModule
  ],
  providers: [
    httpInterceptorProviders,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthHttpInterceptor,
    //   multi: true,
    // },
   /* {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    }, */
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
