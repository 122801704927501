<div class="min-h-full flex">
  <div
    class="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 max-w-2xl w-100"
  >
    <div class="mx-auto w-full max-w-lg lg:w-96 mt-16">
      <div>
        <img class="h-12 w-auto" src="assets/img/logo-v1.png" alt="Workflow" />
      </div>
      <h3 class="mt-4 font-extrabold text-gray-900 break-normal">
        Reset Your password
      </h3>

      <div class="mt-8">
        <div class="mt-6">
          <h1 class="error" *ngIf="!validToken">{{ invalidTokenMsg }}</h1>

          <form
            *ngIf="validToken"
            [formGroup]="forgotForm"
            (ngSubmit)="onSubmit()"
          >
            <div>
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div class="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  formControlName="password"
                  autocomplete="password"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                for="confirm_password"
                class="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <div class="mt-1">
                <input
                  id="confirm_password"
                  name="password"
                  type="password"
                  formControlName="confirm_password"
                  autocomplete="password"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <p
                  [ngClass]="{ error: error, success: !error }"
                  *ngIf="message"
                >
                  {{ message }}
                </p>
              </div>
            </div>

            <div>
              <button
                [disabled]="!forgotForm.valid || loading"
                type="submit"
                value="{{ loading ? 'LOADING...' : 'RESET PASSWORD' }}"
                class="mt-2 mb-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 disabled:bg-gray-400"
              >
                Reset password
              </button>

              <a [routerLink]="['/login']" class="text-blue-800"
                >Login Instead</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="rightPhoto relative w-100 w-full">
    <img
      class="absolute inset-0 h-full w-100 object-cover object-top"
      src="assets/img/img-login.png"
      alt=""
    />
  </div>
</div>
