import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { STRINGS } from "../../../utils/constants";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  public forgotForm: UntypedFormGroup;
  public message: string;
  public loading = false;
  public error = false;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    fb: UntypedFormBuilder
  ) {
    this.forgotForm = fb.group({
      email: ["", Validators.required],
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    this.loading = true;
    this.message = "";
    const email = this.forgotForm.value.email;
    this.userService.forgot(email).subscribe(
      () => {
        this.error = false;
        this.message = STRINGS.RESET_INSTRUCTIONS_SENT;
        this.loading = false;
      },
      (response) => {
        this.error = true;
        this.message = response.error.message;
        this.loading = false;
      }
    );
  }
}
