import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { MatSidenav } from "@angular/material/sidenav";
import { UserService } from './services/user.service';
import { BusinessService } from './services/business.service'
import { Subject, takeUntil } from 'rxjs';
import { navConfig } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentUser: any;
  business: any;
  title = 'admin-dashboard';
  routes = [{
    name: 'Gratas',
    icon: 'face-smile',
    path: '/gratas'
  }, {
    name: 'Directory',
    icon: 'user',
    path: '/directory',
  }, {
    name: 'Groups',
    icon: 'users',
    path: '/groups',
  }, {
    name: 'Settings',
    icon: 'gear',
    path: '/settings',
  }]
  locations: any = []
  selectedLocation: any;
  mobileQuery: MediaQueryList;
  helpUrl: string = 'https://support.gratapro.com/knowledge'
  private _mobileQueryListener: () => void;
  private unsubscribe = new Subject<void>();
  private filtersChanged = new Subject<void>();
  isGrataFlow: boolean = false;
  configuration:any
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    private router: Router,
    private location: Location,
    private auth: AuthService,
    public userService: UserService,
    private businessService: BusinessService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.selectedLocation = this.locations[0]
    this.checkForGrataFlow()
  }

  ngOnInit(){
    this.auth.idTokenClaims$.subscribe((profile) => {
      if(profile){
        localStorage.setItem('authToken',profile.__raw);
        this.configuration = navConfig
        this.configuration['authDetails']['token'] = JSON.stringify(profile.__raw)
      }
    });

    this.checkForGrataFlow()
    this.getUpdatedUserDetails()

    this.filtersChanged.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (!this.business?.id) {
        return;
      }
      this.getUpdatedUserDetails()
    });

    this.userService.selectedBusiness.pipe(takeUntil(this.unsubscribe)).subscribe(business => {
      this.business = business;
      this.filtersChanged.next();
    });
  }

  getUpdatedUserDetails() {
    this.userService.currentUser.subscribe(user => {
      this.userService.getProfileUser(user.id.toString()).subscribe(details => {
        // console.log('DETAILS', details)
        localStorage.setItem('currentUser', JSON.stringify(details))
        this.currentUser = details;
        this.currentUser.first_initial = this.currentUser.first_name.charAt(0)
        this.currentUser.last_initial = this.currentUser.last_name.charAt(0)
        let adminLocations = []
        this.currentUser.businesses.map(business => {
          if(business.user_business_role.role_id == 2 || business.user_business_role.role_id == 0) {
            adminLocations.push(business)
          }
        })
        this.locations = adminLocations;
      })
    })
  }

  checkForGrataFlow() {
    // console.log(this.location.path(true))
    let urlFragmentCheck1 = this.location.path(true).substring(
      this.location.path(true).indexOf("/") + 1,
      this.location.path(true).lastIndexOf("?")
    )

    let urlFragmentCheck2 = this.location.path(true).substring(
      this.location.path(true).indexOf("/") + 1,
      this.location.path(true).lastIndexOf("/")
    )

    if(urlFragmentCheck1 == 'submit-rating' || urlFragmentCheck2 == 'submit-rating') {
      // console.log(this.location.path(true))
      // console.log('grata flow detected')
      this.isGrataFlow = true
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onLocationChange(event) {
    let businessId = event.value
    // console.log(event)
    this.businessService.getBusiness(businessId).subscribe(result => {
      // console.log(result)
      this.business = result
      this.userService.selectedBusiness.next(this.business)
      this.filtersChanged.next()
    })
  }

  navigateToPage(value: string) {
    // console.log('Navigating to...', value)
    this.router.navigateByUrl(value)
  }

  openHelpUrl() {
    window.open(this.helpUrl, "_blank");
  }

  onActionHandler(event) {
    // console.log(event)
  }
  login(){
  }
  redirect(data){
    if(data.inApp){
      this.router.navigate([data.path])
    }else{
      window.location.href = data.path
    }
  }
  searchNavigation(data){
    window.location.href = navConfig.navigationUrls.appUrl.path +data.link
  }
}
